import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout"
import "../../../styles/odooServices.scss"
import "../../../styles/services.scss"
import "../../../styles/caseStudy.scss"
import News from "../../../components/News"
import ProductTopSection from "../../../components/ProductTopSelection"
import WhyChooseVisiomateforD365 from "../../../components/WhyChooseVisiomateforD365"
import Wethoughtyoumighthavequestions from "../../../components/Wethoughtyoumighthavequestions"
import CaseStudeiesSimplified from "../../../components/CaseStudeiesSimplified"
import ImageWithTextSection from "../../../components/ImageWithTextSection"
const MD365BulkStockDistribution = ({ data }) => {
  return (
    <div className="CaseStudeies">
      <Layout pageTitle={"Visiomate - Microsoft Dynamics 365 Distribution Utility"}
      descrip = {"Transform your distribution and utility business with the power of Dynamics 365. Gain real-time insights, improve efficiency, and drive growth. Explore now!"}>
        <ProductTopSection
          headerData={{
            title: "Distribution Utility: Streamline your supply chain. Bulk distribution simplified. ",
            peragraph: "A bulk distribution utility seemlessly integrated with Dynamics 365. Internal transfers and wholesale order creation is covered."
            // , image: headerImage
          }}
          data={data}
        />
        <WhyChooseVisiomateforD365
          data={{
            title: "Why Choose Visiopack?",
            headings: [
              {
                heading: "Operational Efficiency",
                description:
                  "Utility optimizes warehouse processes, automating routine tasks and fostering a more efficient operational environment.",
              },
              {
                heading: "Optimized Inventory Control",
                description:
                  "Utility streamlines inventory distribution, reducing holding costs, and ensuring timely fulfillment of orders.",
              },
              {
                heading: "Holistic Data Integration",
                description:
                  "Utility integrates with Dynamics 365 seemlessly, optimising the user experience with increased ease of use.",
              },
              {
                heading: "Time and Cost Savings",
                description:
                  "Automation of tasks, such as bulk transfer and salse order creation for multiple stores and cusotmer, saves time and reduces the need for manual labor.",
              },
            ],
          }}
          dataFormImages={data}
        />
        <ImageWithTextSection
          caseStudyData={{
            data: [
              {
                heading: "Multiple Destination Location Selection",
                description:
                  "User can select multiple destination locations to create Transfer Order.",
              },
              {
                heading: " Continent Selection or all at once",
                description:
                  " User can use select all option by just clicking on the select all checkbox",
              },
              {
                heading: " Filter by D365 Hierarchy & Category  ",
                description:
                  "Utility provides filter options based on the D365 hierarchy & category configuration",
              },
              {
                heading: "Filter by Product & Product Dimensions ",
                description:
                  "Utility provides ability to further narrow down by selecting the desired product and product dimensions. ",
              },
              {
                heading: " Distribution Management ",
                description:
                  "User can create as many as distributions and process them later at his / her convenience.",
              },
              {
                heading: "Flexible grid view to fill up the distribution demand",
                description:
                  "User can scroll horizontally or Vertically with fixed Headers to fill up the demand with ease.",
              },
            ],
          }}
          dataFormImages={data}
        />
        <CaseStudeiesSimplified
          TextData={{
            title: "Simplified Steps to get you Started ",
            Data: [
              {
                peragraph: "Define Requirements",
                list: [
                  "Clearly outline your business objectives and identify the specific challenges or needs that the software solution should address.",
                  "List down the essential features and functionalities required.",
                ],
              },
              {
                peragraph: "Demo and Evaluation",
                list: [
                  "Schedule product demonstrations. ",
                  "Evaluate the software's usability, features, and performance. ",
                  "Engage key stakeholders in the evaluation process.",
                ],
              },
              {
                peragraph: "Scalability and Integration",
                list: [
                  "Identify the potential integrations required by the customer.",
                  "Plan the implementation of identified integrations.",
                ],
              },
              {
                peragraph: "UAT & User Feedback",
                list: [
                  "UAT period allows customer to assess the system performance and suitability for the specific business needs.",
                  "Customer’s insights can provide valuable perspectives on usability, functionality mismatch.",
                ],
              },
              {
                peragraph: "User Training",
                list: [
                  " User training is a critical component of successfully implementing the software solution.",
                  "Proper training ensures that users can effectively and efficiently utilize the software, maximizing its benefits.",
                ],
              },
              {
                peragraph: "Post-Implementation Support",
                list: [
                  "Provide ongoing support for users.",
                  "Address any post-implementation issues.",
                  "Evaluate and implement system enhancements based on feedback.",
                ],
              },
            ],
          }}
        />
        <Wethoughtyoumighthavequestions
          data={[
            {
              question:
                "What is the Distribution Utility? ",
              answer:
                "Distribution Utility is integrated solution for creating bulk transfer and wholesale Orders. This uThe purpose of this utility is to provide users ability to create transfer order in bulk from outside the Microsoft Dynamics D365. It is a very light weight application, with straight forward installation process.",
                answer1:
                "This application helps users to reduced their warehouse operations work load to create multiple transfer orders with no time from outside the Microsoft Dynamics 365 promises. Secondly all the users who may use this application would not require any Microsoft Dynamics 365 license.",
                answer2:
                  "",
            },
            {
              question:
                "Who can use the Distribution Utility?",
              answer:
                "Any organization that already has Microsoft Dynamics 365 Finance and Operations as there core ERP system can use this utility.",
            },
            {
              question:
                "What is the Licensing mechanism for this Distribution Utility?",
              answer:
                "First it dust not require extra license of D365. An organization can buy a lifetime valid license (for three users) by paying one time payment.",
            },
            {
              question:
                "Is Support for upgrade is available in case new D635 version does not work?",
              answer:
                "Yes, Visiomate team believe and solving problem no selling products. Our team will keep upgrading the utility for new version of D365.",
            },
            {
              question:
                "Can this utility be personized by have some special features if any organization require?",
              answer:
                "We can discuss the scenario and discuss if the personlization is valid requirement, it is possible to provide and personalized version.",
            },
            {
              question:
                "How can i reach out to visiomate to get this utility?",
              answer:
                "You can reach out to Visiomate team by leting us know about you query through Let Us know About Your Projectnpm start feature on our website",
            },
          ]}
          title="We thought you might have questions"
        />
        <News isAbout={true} career={true} />
      </Layout>
    </div>
  )
}

export default MD365BulkStockDistribution
export const query = graphql`
  query MD365BulkStockDistributionImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    HeaderImg: file(
      relativePath: { eq: "DistributionD365/dist_top_header.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImages: file(relativePath: { eq: "prodbg-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img1: file(relativePath: { eq: "DistributionD365/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(relativePath: { eq: "DistributionD365/2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(relativePath: { eq: "DistributionD365/3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img4: file(relativePath: { eq: "DistributionD365/4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(relativePath: { eq: "DistributionD365/5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img6: file(relativePath: { eq: "DistributionD365/6.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
